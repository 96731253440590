.navigation {
  min-height: $navbar-height-mobile;
  background-color: transparent;
  border-bottom: 2px solid;
  border-bottom: 2px solid rgba($border-color, $alpha: 0.5);

  @include media-breakpoint-up(sm) {
    height: $navbar-height;
  }

  .nav-link {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
  }
}

.nav-item {
  display: none;
  width: 100%;
  @include media-breakpoint-up(sm) {
    margin-left: 40px;
  }
}

.nav-item--show {
  display: list-item;
}

.nav-text {
  border-bottom: 2px solid transparent;
  margin-bottom: -2px;
}

.nav-icon {
  font-size: 2rem;
  margin-right: 10px;
}

.nav-link:hover {
  .nav-text {
    border-bottom: 2px solid;
  }
}

.navbar-toggler {
  .nav-burger {
    display: none;
  }
  .nav-close {
    display: block;
  }
  &.collapsed {
    .nav-burger {
      display: block;
    }
    .nav-close {
      display: none;
    }
  }
}

.nav-burger, .nav-close {
  color: $icon-color;
  font-size: 2rem;
}

.nav-activePage {
  border-bottom: 2px solid;
}
