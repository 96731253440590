main {
  min-height: calc(100vh - #{$page-offset-mobile} - #{$navbar-height-mobile} - #{$footer-height-mobile});
  @include media-breakpoint-up(sm) {
    min-height: calc(100vh - #{$page-offset} - #{$navbar-height} - #{$footer-height});
  }
}

h1 {
  letter-spacing: -0.63px;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

strong {
  font-weight: $font-weight-bold;
}

p {
  margin-bottom: 20px;
}

input {
  width: 350px;
}

hr {
  width: 257px;
  height: 2px;
  margin-left: 0px;
  margin-right: 0px;
}

.ul-inline {
  display: inline;
}

@include media-breakpoint-up(sm) {
  body {
    font-size: $font-size-base-sm;
  }

  h1 {
    font-size: $font-size-base-sm * 3.125;
    margin-bottom: 40px;
  }
}

// Bootstrap override
body {
  .link-hover-effect:hover {
    color: #d9d9d9;
    cursor: pointer;
  }
  .underlined-link {
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 10px;
    display: block;
  }
  .underlined-link:hover {
    color: #d9d9d9;
    cursor: pointer;
    text-decoration: underline;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background: transparent;
    cursor: not-allowed;
  }
}
