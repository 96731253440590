.icon-btn-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  .icon-btn {
    display: inline-block;
    max-width: 80px;
    height: 120px;
    font-size: $font-size-base;
    border-width: 2px;
    .big-icon-btn {
      font-size: 40px;
      margin-bottom: 15px;
    }
  }
  .btn {
    min-width: 90px;
  }
  @include media-breakpoint-up(sm) {
    .icon-btn {
      display: inline-block;
      max-width: 120px;
      height: 140px;
      font-size: $font-size-base-sm;
      margin-right: 10px;
    }
  }
}
