// Directories and Paths
$assets-path: "../../assets";
$fonts-path: "#{$assets-path}/webfonts";

// Fontawesome variables
$fa-font-path: "#{$fonts-path}/fontawesome";

// Default Colors
$white: #FFFFFF;
$black: #000000;

// YOO-Colors
$yoo-blue: #0077c8;
$yoo-turquoise: #4ec6de;
$yoo-pink: #ff6584;
$yoo-lavender: #575a89;
$yoo-lavender-dark: #373e46;
$yoo-lavender-light: #d0cde1;

$yoo-blue-gradient: linear-gradient(90deg, rgb(0, 95, 159) 0%, rgb(56, 160, 174) 100%);
$yoo-blue-gradient-filled: linear-gradient(90deg, rgb(0, 95, 159) 100%, rgb(56, 160, 174) 0%);

// Fonts
$font-weight-bold: 700;
$font-size-base: 0.75rem;
$font-weight-light: 300;
$font-weight-base: $font-weight-light;
$font-size-base-sm: 1rem;
$h1-font-size: 1.5rem;

// Bootstrap Variables
// Theme Colors
$primary: $yoo-blue;
$secondary: $yoo-turquoise;

// Fonts
$font-family-base: "Clan W05";
$headings-font-weight: $font-weight-bold;
$h1-font-size: $font-size-base * 3.125;
$input-btn-font-size: $font-size-base-sm;

// $grid-gutter-width: 120px;

// Inputs
$input-btn-padding-y: 0.75rem;
$form-group-margin-bottom: 1.25rem;

// Button
$btn-font-weight: 500;
$participant-button-hover-color: rgb(224, 224, 224);

// Navigation
$footer-height: 62px;
$navbar-height: 70px;
$page-offset: 140px;

$footer-height-mobile: 62px;
$navbar-height-mobile: 60px;
$page-offset-mobile: 60px;
