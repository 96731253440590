.page-index {
  @include media-breakpoint-up(sm) {
    .btn {
      min-width: 220px;
      max-width: 250px;
    }
  }
}

.yoo-hacks-container {
  margin-top: 75px;
}