.introduction-modal {
  &-outro {
    margin-top: 20px;
    font-weight: 500;
  }
  h2 {
    color: $yoo-blue;
  }
  .modal-row {
    margin-bottom: 10px;
    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: center;
    }
  }
  .modal-body {
    margin-bottom: 30px;
  }
}

.modal-login-link-box {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.modal-dark-title {
  color: $yoo-blue;
}

.modal-link {
  color: #000000;
}

.modal-link:hover {
  color: #515151;
}

.dark-theme-modal {
  h2 {
    color: $yoo-blue;
  }
}

.modal {
  color: $black;
  .btn {
    min-width: 100px;
  }
}

.modal-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
}

.bullet-col {
  padding-right: 20px;
}

.list-group-item {
  border-style: none;
}

.modal-icon {
  font-size: 2.5rem;
}

.modal-title {
  margin-bottom: 30px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 40px;
  }
}

.modal-header {
  border-bottom: none;
}

.modal-button-container {
  margin-top: 40px;
  margin-bottom: 40px;
  .btn {
    margin-right: 40px;
  }
  &:last-child {
    margin-right: 0;
  }
}

.yoo-blue-color {
  color: $yoo-blue;
}

