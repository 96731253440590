.page-register {
  .inverted-message {
    color: $yoo-blue;
    background-color: white;
    padding: 5px;
    border-radius: 15px;
    margin-bottom: 40px;
    padding-bottom: -15px;
  }
  #data-protection-agreement-check {
    width: unset;
  }
  #send-mail-again-link {
    display: block;
    margin-top: 10px;
  }
  @include media-breakpoint-up(sm) {
    .btn {
      min-width: 220px;
      max-width: 250px;
    }
  }
}
