.page-login {
  #send-mail-again-link {
    display: block;
    margin-top: 10px;
  }
  @include media-breakpoint-up(sm) {
    .btn {
      min-width: 220px;
      max-width: 250px;
    }
  }
}
