
@import "../abstracts/variables";

@font-face {
  font-family: "Clan W05";
  font-weight: 300;
  font-style: normal;
  src:
    url("#{$fonts-path}/yoo/ClanW05-Light.woff2") format("woff2"),
    url("#{$fonts-path}/yoo/ClanW05-Light.woff") format("woff");
}

@font-face {
  font-family: "Clan W05";
  font-weight: 300;
  font-style: italic;
  src:
    url("#{$fonts-path}/yoo/ClanW05-Light-Italic.woff2") format("woff2"),
    url("#{$fonts-path}/yoo/ClanW05-Light-Italic.woff") format("woff");
}

@font-face {
  font-family: "Clan W05";
  font-weight: 400;
  font-style: normal;
  src:
    url("#{$fonts-path}/yoo/ClanW05-Regular.woff2") format("woff2"),
    url("#{$fonts-path}/yoo/ClanW05-Regular.woff") format("woff");
}

@font-face {
  font-family: "Clan W05";
  font-weight: 400;
  font-style: italic;
  src:
    url("#{$fonts-path}/yoo/ClanW05-Regular-Italic.woff2") format("woff2"),
    url("#{$fonts-path}/yoo/ClanW05-Regular-Italic.woff") format("woff");
}

@font-face {
  font-family: "Clan W05";
  font-weight: 700;
  font-style: normal;
  src:
    url("#{$fonts-path}/yoo/ClanW05-Bold.woff2") format("woff2"),
    url("#{$fonts-path}/yoo/ClanW05-Bold.woff") format("woff");
}

@font-face {
  font-family: "Clan W05";
  font-weight: 700;
  font-style: italic;
  src:
    url("#{$fonts-path}/yoo/ClanW05-Bold-Italic.woff2") format("woff2"),
    url("#{$fonts-path}/yoo/ClanW05-Bold-Italic.woff") format("woff");
}
