.page-edit-draw  {
  .show-secret-santa-btn {
    display: none;
  }
  &.is-sent {
    .show-secret-santa-btn {
      display: block;
    }
    .edit-participant-btn, .delete-participant-btn {
      display: none;
    }
  }
  .modal-dialog {
    max-width: 400px;
    max-height: 397px;
  }
  .modal-image {
    margin-left: 0em;
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .btn-group-edit-draw {
    width: 100%;
    @include media-breakpoint-up(sm) {
      .btn {
        width: auto;
        margin-bottom: 10px;
        margin-right: 40px;
      }
    }
  }
  .p-margin {
    margin-bottom: 20px;
  }
  .icon-link {
    color: $yoo-turquoise;
    display: flex;
    display: inline-block;
    margin-bottom: 15px;
  }
  .direction-column {
    display: flex;
    flex-flow: column;
  }
  .btn-delete-draw {
    margin-bottom: 20px;
  }
  .section {
    margin-bottom: 50px;
  }
}
