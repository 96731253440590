.btn-secondary {
  color: $white;
}
.btn-outline-secondary {
  &:hover:not(:disabled),
  &:active,
  &:not(.disabled):not(:disabled).active {
    color: $white;
  }
}

.btn-light {
  color: $primary;
}

.btn {
  letter-spacing: 0.1px;
  $input-btn-padding-y: 0.8125rem;
  $input-btn-padding-x: 2rem;
  margin-top: 20px;
  margin-bottom: 20px;
  &:disabled {
    cursor: not-allowed;
  }
}

.btn-yoo-gradient {
  background-image: $yoo-blue-gradient;
  color: $white;
  &:hover {
    background-image: $yoo-blue-gradient-filled;
    color: $white;
  }
}

.btn-fullwidth {
  margin-bottom: 10px;
  width: 100%;
}

.btn-light {
  &:hover,
  &:active,
  &:focus {
    color: $yoo-blue;
  }
}

.btn-outline-light {
  &:hover,
  &:active {
    color: $yoo-blue;
  }
}
