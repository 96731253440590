.colored-bullet {
  background: linear-gradient(-180deg, rgb(56, 160, 174) 0%, rgb(0, 95, 159) 100%);
  border-radius: 999px;
  height: 35px;
  width: 35px;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $white;

  @include media-breakpoint-up(sm) {
    height: 52px;
    width: 52px;
    font-size: 1.5rem;
  }
}
