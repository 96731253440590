.page-profile {
  .btn {
    width: 100%;
    margin-bottom: 20px;
  }
  .btn-toolbar {
    width: 100%;
  }
  @include media-breakpoint-up(sm) {
    .btn {
      min-width: 160px;
      width: auto;
    }
    .btn-toolbar {
      display: inline-flex;
      justify-content: space-between;
    }
    .mood-image {
      max-width: 255px;
    }
    + .page {
      margin-bottom: 140px;
    }
  }
}
