.draw-message {
  background: $yoo-blue-gradient;
  color: $white;
  border-radius: 4px;
  margin-bottom: 50px;
}
.draw-message__content {
  padding: 20px;
}
.draw-message__title {
  color: $white;
  margin-bottom: 20px;
}
.draw-message__btn-group {
  .btn {
    margin-bottom: 20px;
    width: 100%;
  }
  :last-child {
    margin-bottom: 0;
  }
  @include media-breakpoint-up(sm) {
    .btn {
      margin-right: 20px;
      margin-bottom: 0;
      width: auto;
    }
    :last-child {
      margin-right: 0;
    }
  }
  @include media-breakpoint-up(md) {
    .btn {
      margin-right: 20px;
      margin-bottom: 20px;
    }
    :last-child {
      margin-right: none;
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    .btn {
      margin-right: 20px;
      margin-bottom: 0;
    }
    :last-child {
      margin-right: none;
    }
  }
}
