.email-preview__image {
  width: 100%;
}
.email-preview__image-title {
  position: absolute;
  top: 25px;
  left: 16px;
  color: $white;
  font-size: 34px;
  font-style: italic;
  font-weight: 500;
}
.email-preview__background {
  background: $yoo-blue-gradient;
  position: relative;
}
.email-preview__info {
  color: $white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}
.email-preview__info-group {
  display: inline-flex;
  flex-flow: column;
  margin-bottom: 10px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 0;
  }
}
.email-preview__label {
  font-size: 14px;
  font-weight: bold;
  width: 100%;
}
.email-preview__value {
  font-size: 14px;
  opacity: 0.75;
  width: 100%;
}

.email-preview__separator {
  background-color: $white;
  opacity: 0.5;
  height: 1px;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 0;
  margin-bottom: 0;
}
.email-preview__title {
  color: $white;
}
.email-preview__text-container {
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.2);
  background-color: $white;
  min-height: 400px;
  position: relative;
}
.email-preview__text {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 30px;
  padding-bottom: 80px;
}
.email-preview__yoo-logo {
  position: absolute;
  bottom: 0;
  right: 20px;
  width: 200px;
}
