.footer {
  height: $footer-height-mobile;
  background-color: transparent;
  border-top: 2px solid;
  border-top: 2px solid rgba($border-color, $alpha: 0.5);

  @include media-breakpoint-up(sm) {
    height: $footer-height;
  }

  .footer-link {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
  }
}

.footer-item {
  width: 100%;
  @include media-breakpoint-up(sm) {
    margin-left: 40px;
  }
}