.draw-list {
  .list-group {
    border-radius: 0em;
  }
}

.draw-box {
  margin-bottom: 40px;
}

.draw-row {
  background: transparent;
  border-top: 1px solid $list-item-border-color;
  border-bottom: 1px solid $list-item-border-color;
  padding-right: 0px;
  padding-left: 0px;
}

.draw-title {
  margin-bottom: 20px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 30px;
  }
}
