.editor {
  background: $yoo-blue-gradient;
  color: $white;
  padding: 20px;
  margin-bottom: 20px;
  .form-control.is-invalid {
    border-color: $white;
  }
  .invalid-feedback {
    background: linear-gradient(88deg, rgb(255 91 91) 38%, rgb(237 81 81) 100%);
    box-shadow: 0px 0px 20px 0px #423434;
    color: $white;
  }
  .error-message-red {
    color: #f66e6e;
  }
}
.editor__form-group {
  margin-bottom: 30px;
}
.editor__form-control {
  background-color: transparent;
  color: $white;
  &:focus {
    background-color: transparent;
    color: $white;
  }
}
.editor__hint {
  font-style: italic;
}
.editor--xmas {
  .editor__image--present,
  .editor__image--space {
    display: none;
  }
}
.editor--present {
  .editor__image--xmas,
  .editor__image--space {
    display: none;
  }
}
.editor--space {
  .editor__image--xmas,
  .editor__image--present {
    display: none;
  }
}
.editor__image {
  width: 100%;
}
.editor__title {
  color: $white;
}
.editor__separator {
  background-color: $white;
  opacity: 0.5;
  height: 1px;
  width: 100%;
}
.editor__label {
  align-items: center;
  display: inline-flex;
}
.editor__optional {
  line-height: 1;
  margin-bottom: 0.5rem;
  margin-left: 5px;
}
.editor__form {
  padding-left: 30px;
  padding-right: 30px;
}
