.participant-list {
  margin-bottom: 40px;
  .list-group {
    border-radius: 0em;
  }
  .fa-plus, .fa-minus {
    color: $yoo-turquoise;
  }
  .btn-link {
    color: $yoo-turquoise;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .edit-btn {
    text-decoration: none;
    font-size: 1.25em;
  }
  .delete-btn {
    font-size: 1.25em;
  }
  .card-header {
    padding: 0;
    background-color: transparent;
    margin-bottom: 20px;
  }
  .list-group {
    border-radius: 0;
    margin-bottom: 20px;
  }
}

.participant-row {
  background: transparent;
  border-bottom: 1px solid rgba($list-item-border-color, 0.5);
  padding: 0;
}

.participant-row-content {
  margin-bottom: 8px;
  margin-top: 8px;
}

.participant-title {
  margin-bottom: 20px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 30px;
  }
}

.participant-edit-form {
  border-top: 1px solid $list-item-border-color;
}

.participant-edit-title {
  margin-top: 20px;
  margin-bottom: 30px;
}

.btn-save-particiapnt {
  margin-bottom: 20px;
}

.participant-collapsed {
  .participants-pen {
    display: block;
  }
  .participants-times {
    display: none;
  }
  &.participants-form-active {  
    .participants-pen {
      display: none;
    }
    .participants-times {
      display: block;
    }
  }
}

.participant-button:hover {
  background-color: rgb(224, 224, 224);
}

.participant-button-toggler {
  .fa-plus {
    display: none;
  }
  .fa-minus {
    display: block;
  }
  &.collapsed {
    .fa-plus {
      display: block;
    }
    .fa-minus {
      display: none;
    }
  }
}

