.page-drawlist {
  .introduction {
    margin-bottom: 60px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 80px;
    }
  }
  @include media-breakpoint-up(sm) {
    .btn {
      min-width: 220px;
      max-width: 250px;
      margin-top: -20px !important;
    }
  }
}
